import * as React from 'react';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { DATETIME_FORMAT } from "services/format";
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent
} from "@mui/lab";
import SubCard from "ui-component/cards/SubCard";
import { GRID_SPACING } from "constants";

const Status = ({ data }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <SubCard>
            <Grid container spacing={GRID_SPACING}>
                <Grid item>
                    <Timeline
                        position={isSmallScreen ? "right" : "alternate"}
                        sx={{
                            "& > li": {
                                mb: 2.75,
                                [theme.breakpoints.down("md")]: {
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    "& > div": {
                                        px: 0
                                    },
                                    "& > div:first-of-type": {
                                        textAlign: "left"
                                    }
                                }
                            },
                            [theme.breakpoints.down("md")]: {
                                p: 0
                            }
                        }}
                    >
                        {data.map((el, index) => (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent
                                    color="text.primary"
                                    sx={{
                                        flex: isSmallScreen ? 0 : 1,
                                        fontSize: isSmallScreen ? '0.875rem' : '0.875rem' // Smaller font size on small screens
                                    }}
                                >
                                    {DATETIME_FORMAT(el.date)}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    {!isSmallScreen && <TimelineDot />}
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize: isSmallScreen ? '0.775rem' : '0.875rem' // Adjusted font size for titles
                                        }}
                                    >
                                        {el.status === "payment.charge.created.v2" ? "Charged" :
                                            el.status === "payment.created" ? "Created" :
                                                el.status}
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        variant="body2"
                                        sx={{
                                            fontSize: isSmallScreen ? '0.75rem' : '0.875rem' // Adjusted font size for remarks
                                        }}
                                    >
                                        {el.remarks}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </Grid>
            </Grid>
        </SubCard>
    );
};

export default Status;
