import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import { Dialog, IconButton, Box, Tabs, Tab } from "@mui/material";
import TableData from "./TableData";
import TrackingData from "./TrackingData";
import MainCard from "ui-component/cards/MainCard";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import { useEffect, useState } from "react";
import "./style.css";
import { getData } from "services";
import { SOURCE_CURRENCY } from "constants";
import { encrypt } from "utils/encDes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
    <DialogTitle sx={{ m: 0, px: 2, py: 1 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
        ) : null}
    </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node
};

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

export default function FormDialog({ open, setOpen, order }) {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const [orderData, setOrderData] = useState([]);
    const [receipient, setReceipient] = useState([]);
    const [trackingData, setTrackingData] = useState([]);

    useEffect(() => {
        if (order) {
            const orderDetails = [
                { key: "Order No", value: order.order_no },
                { key: "Status", value: order.order_status },
                { key: "Service", value: order.service },
                { key: "Remittance ID", value: order.remittance_id },
                { key: "Sending Amount", value: order.sending_amount, post: SOURCE_CURRENCY.currency_code },
                { key: "Exchange Rate", value: order.exchange_rate, post: SOURCE_CURRENCY.currency_code },
                { key: "Charged Fee", value: order.charged_fee, post: SOURCE_CURRENCY.currency_code },
                { key: "Total Amount", value: order.total_amount_to_pay, post: SOURCE_CURRENCY.currency_code },
                { key: "Receiving Amount", value: order.receiving_amount, post: "USD" }
            ];
            if (order.taaj_error_message) {
                orderDetails.push({ key: "Taaj Error", value: order.taaj_error_message });
            }
            setOrderData(orderDetails);

            setReceipient([
                { key: "Name", value: order.recipient_name },
                { key: "Mobile", value: order.recipient_mobile_no },
                { key: "Bank Acc", value: order.recipient_account_no },
                { key: "Country", value: order.recipient_country },
                { key: "City", value: order.recipient_city },
                { key: "Relation", value: order.recipient_relation },
                { key: "Payment Purpose", value: order.payment_purpose }
            ]);

            getData(`/en/admin/orders/${encrypt(order.order_no)}/customers/${encrypt(order.user_id)}`)
                .then(response => {
                    if (response.data?.data?.items?.length > 0) {
                        const itemsData = response.data.data.items.map(el => {
                            return {
                                date: el.date,
                                status: el.tracking_status,
                                remarks: el.remarks + (el.by_whom ? ` (${el.first_name} ${el.last_name})` : " (System)")
                            };
                        });
                        setTrackingData(itemsData);
                    }
                });
        }
    }, [order]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Order Details
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ py: 0 }}>
                <MainCard>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        variant="scrollable"
                        aria-label="simple tabs example"
                        sx={{
                            "& a": {
                                minHeight: "auto",
                                minWidth: 10,
                                px: 1,
                                py: 1.5,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center"
                            },
                            "& a.Mui-selected": {
                                color: theme.palette.primary.main
                            },
                            "& a > svg": {
                                marginBottom: "0px !important",
                                marginRight: 1.25
                            },
                            mb: 3
                        }}
                    >
                        <Tab
                            icon={<DescriptionTwoToneIcon />}
                            component={Link}
                            to="#"
                            label="Order"
                            {...a11yProps(0)}
                        />
                        <Tab
                            icon={<ReceiptTwoToneIcon />}
                            component={Link}
                            to="#"
                            label="Receipient"
                            {...a11yProps(1)}
                        />
                        <Tab
                            icon={<LocalShippingTwoToneIcon />}
                            component={Link}
                            to="#"
                            label="Tracking History"
                            {...a11yProps(2)}
                        />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <TableData data={orderData} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <TableData data={receipient} />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <TrackingData data={trackingData} />
                    </TabPanel>
                </MainCard>
            </DialogContent>
        </BootstrapDialog>
    );
}
