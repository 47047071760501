import { LOGIN, LOGOUT } from "./actions";

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
    case LOGIN: {
        const { user } = action.payload;

        return {
            ...state,
            isLoggedIn: true,
            isInitialized: true,
            user
        };
    }
    case LOGOUT: {
        return {
            ...state,
            isInitialized: true,
            isLoggedIn: false,
            user: null
        };
    }
    default: {
        return { ...state };
    }
    }
};

export default accountReducer;
