import { GRID_SPACING, CONFIRMATION_TYPES } from "constants";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { addData, getData, updateData } from "services";
import { useFormik } from "formik";

const Form = ({
    open,
    callback,
    refreshData,
    title,
    url,
    type,
    fields = [],
    validationSchema
}) => {
    const theme = useTheme();

    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if (fields.length > 0) {
            let obj = {};
            fields.forEach((el) => (obj[el.id] = el.prefix ? el.value.replace(el.prefix, "") : el.value));
            setInitialValues(obj);
        } else {
            setInitialValues({});
        }
    }, [fields]);

    const handleClose = () => {
        callback();
    };

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (values.mobile_no) { values.mobile_no = "+46" + values.mobile_no }
            if (type === CONFIRMATION_TYPES.INSERT) {
                await addData(url, values);
            } else if (type === CONFIRMATION_TYPES.UPDATE) {
                await updateData(url, values);
            }
            if (values.mobile_no) {
                values.mobile_no = values.mobile_no.replace("+46", "");
            }
            callback();
            refreshData();
        }
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 3 }}
        >
            {open && (
                <div style={{ minWidth: 500 }}>
                    <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: 0 }}>
                        {title}
                    </DialogTitle>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={GRID_SPACING}>
                                {fields.map((el, index) => {
                                    switch (el.type) {
                                    case "select":
                                        return (
                                            <Grid
                                                display={el?.hidden && "none"}
                                                item
                                                key={index}
                                                xs={12}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id={el.id}>{el.label}</InputLabel>
                                                    <Select
                                                        id={el.id}
                                                        name={el.id}
                                                        label={el.label}
                                                        defaultValue={formik.values[el.id]}
                                                        value={formik.values[el.id] || ""}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.touched[el.id] &&
                                Boolean(formik.errors[el.id])
                                                        }
                                                        helpertext={
                                                            formik.touched[el.id] && formik.errors[el.id]
                                                        }
                                                    >
                                                        {el.options.map((option, index) => (
                                                            <MenuItem key={index} value={option[el.id]}>
                                                                {option[el.column]}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {formik.errors[el.id] && formik.touched[el.id] ? (
                                                        <div className="error">{formik.errors[el.id]}*</div>
                                                    ) : null}
                                                </FormControl>
                                            </Grid>
                                        );

                                    case "file":
                                        return (
                                            <Grid
                                                display={el?.hidden && "none"}
                                                item
                                                key={index}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    type={el.type}
                                                    id={el.id}
                                                    name={el.id}
                                                    multiple={el.multiple}
                                                    value={formik.values[el.id] || ""}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched[el.id] &&
                              Boolean(formik.errors[el.id])
                                                    }
                                                    helpertext={
                                                        formik.touched[el.id] && formik.errors[el.id]
                                                    }
                                                />
                                                {formik.errors[el.id] && formik.touched[el.id] ? (
                                                    <div className="error">{formik.errors[el.id]}*</div>
                                                ) : null}
                                            </Grid>
                                        );

                                    case "checkbox":
                                        return (
                                            <Grid
                                                display={el?.hidden && "none"}
                                                item
                                                key={index}
                                                xs={12}
                                                sx={{ display: "flex" }}
                                            >
                                                <Checkbox
                                                    value={formik.values[el.id] || false}
                                                    id={el.id}
                                                    name={el.id}
                                                    label={el.label}
                                                    color="primary"
                                                    sx={{ color: theme.palette.primary.main }}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched[el.id] &&
                              Boolean(formik.errors[el.id])
                                                    }
                                                    helpertext={
                                                        formik.touched[el.id] && formik.errors[el.id]
                                                    }
                                                />
                                                <Typography sx={{ paddingTop: 1.3 }}>
                                                    {el.label}
                                                </Typography>
                                                {formik.errors[el.id] && formik.touched[el.id] ? (
                                                    <div className="error">{formik.errors[el.id]}*</div>
                                                ) : null}
                                            </Grid>
                                        );

                                    case "autocomplete":
                                        return (
                                          <Grid
                                              display={el?.hidden && "none"}
                                              item
                                              key={index}
                                              xs={12}
                                          >
                                              <Autocomplete
                                                  fullWidth
                                                  id={el.id}
                                                  name={el.id}
                                                  label={el.label}
                                                  value={formik.values[el.id] || []}
                                                  onChange={(e , v) => formik.setFieldValue(el.id, v)}
                                                  onBlur={formik.handleBlur}
                                                  clearIcon={false}
                                                  options={[]}
                                                  freeSolo
                                                  multiple
                                                  renderTags={(value, props) =>{
                                                      if(!Array.isArray(value)) {
                                                        value = [value]
                                                      }
                                                      return value.map((option, index) => (
                                                          <Chip label={option} {...props({ index })} key={index} />
                                                      ));
                                                    }
                                                  }
                                                  renderInput={(params) => <TextField label={el.label} {...params} />}
                                                  error={
                                                      formik.touched[el.id] &&
                                                      Boolean(formik.errors[el.id])
                                                  }
                                                  helpertext={
                                                      formik.touched[el.id] && formik.errors[el.id]
                                                  }
                                              />
                                              {formik.errors[el.id] && formik.touched[el.id] ? (
                                                  <div className="error">{formik.errors[el.id]}*</div>
                                              ) : null}
                                          </Grid>
                                        )

                                    default:
                                        return (
                                            <Grid
                                                display={el?.hidden && "none"}
                                                item
                                                key={index}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    type={el.type || "text"}
                                                    id={el.id}
                                                    name={el.id}
                                                    label={el.label}
                                                    value={formik.values[el.id] || ""}
                                                    onChange={async (event) => {
                                                        formik.setFieldValue(el.id, event.target.value);
                                                        if (el.id === "personal_number") {
                                                            const res = await getData(`validate-personnummer?personnummer=${event.target.value}`);
                                                            if (res && res.valid === false) {
                                                                formik.setFieldError(el.id, res.message);
                                                                formik.setFieldTouched(el.id, true)
                                                            }
                                                        }
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched[el.id] &&
                              Boolean(formik.errors[el.id])
                                                    }
                                                    helpertext={
                                                        formik.touched[el.id] && formik.errors[el.id]
                                                    }
                                                    InputProps={{
                                                        startAdornment: el.prefix && (<InputAdornment position='start'>{el.prefix}</InputAdornment>)
                                                    }}
                                                />
                                                {formik.errors[el.id] && formik.touched[el.id] ? (
                                                    <div className="error">{formik.errors[el.id]}*</div>
                                                ) : null}
                                            </Grid>
                                        );
                                    }
                                })}
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{
                                    color: theme.palette.error.dark,
                                    borderColor: theme.palette.error.dark
                                }}
                                onClick={handleClose}
                                color="secondary"
                                type="reset"
                            >
                Cancel
                            </Button>
                            <Button variant="contained" size="small" type="submit" autoFocus>
                                { type === CONFIRMATION_TYPES.INSERT ? 'Add' : "Update"}
                            </Button>
                        </DialogActions>
                    </form>
                </div>
            )}
        </Dialog>
    );
};

export default Form;
