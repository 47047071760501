import { getData } from "services";

export const formatCurrency = (value) => {
    let nf = Intl.NumberFormat("sv-SE");

    if (typeof value === "string") {
        value = Number(value);
    }

    return value ? nf.format(value) : 0;
};

export const downloadReport = (csvContent, filename) => {
    const BOM = "\uFEFF";
    const encodeType = "text/csv;charset=utf-8";
    const csvData = BOM + csvContent;
    const blob = new Blob([csvData], { type: encodeType });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename + ".csv");
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename + ".csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const exportOrdersCsv = (period, filename, data) => {
    const separator = ";";
    const specifiedPeriod = period ?
        "Searched Period\n" + `${period.start} - ${period.end}` + "\n \n \n" :
        "";

    const columns = [
        "order_no",
        "created_at",
        "order_status",
        "sender_name",
        "mobile_no",
        "source_country",
        "",
        "recipient_name",
        "recipient_mobile_no",
        "recipient_city",
        "recipient_country",
        "sending_amount",
        "charged_fee",
        "total_amount_to_pay",
        "receiving_amount",
        "service",
        "payment_purpose",
        "recipient_relation"
    ];

    const displayedColumns = [
        "Order#",
        "Datum",
        "Status",
        "Kund",
        "Kund_mobile",
        "Kund_stad",
        "",
        "Mottagare",
        "Mottagare_mobile",
        "Mottagare_stad",
        "Land",
        "sending_amount",
        "Fee",
        "Total",
        "receiving_amount",
        "service",
        "Purpose",
        "Relation"
    ];

    const csvHeaders = displayedColumns.map((row) => row).join(separator);

    const csvContent =
    specifiedPeriod +
    csvHeaders +
    "\n" +
    data
        .map((row) => {
            return columns
                .map((k) => {
                    let cell = row[k] === null || row[k] === undefined ? "" : row[k];
                    cell =
              cell instanceof Date ?
                  cell.toLocaleString() :
                  cell.toString().replace(/"/g, '""');

                    if (cell.search(/("|,|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }

                    if (
                        ["sending_amount", "charged_fee", "total_amount_to_pay"].includes(
                            k
                        )
                    ) {
                        cell = `${formatCurrency(cell)} ${row.source_currency_code}`;
                    }

                    if (k === "receiving_amount") {
                        cell = `${formatCurrency(cell)} ${row.destination_currency_code}`;
                    }

                    if (k === "order_no") {
                        const order_no = row.order_no ? Number(row.order_no) : null;
                        cell = `${
                            order_no ? order_no.toString().padStart(6, "0") : order_no
                        }`;
                    }

                    return cell;
                })
                .join(separator);
        })
        .join("\n");

    downloadReport(csvContent, filename);
};

export const exportCustomerCsv = (url) => {
    getData(url).then((resp) => {
        const filename = `${new Date().toLocaleDateString("sv-SE")}-customers`;
        const columns = [
            "user_no",
            "personal_number",
            "first_name",
            "last_name",
            "mobile_no",
            "email",
            "street",
            "postcode",
            "city",
            "no_of_orders",
            "last_order_date"
        ];
        const displayedColumns = [
            "user_no",
            "personal_number",
            "Namn",
            "Efternamn",
            "Mobile",
            "email",
            "Adress",
            "Postnummer",
            "Ort",
            "Antal ordrar",
            "Senaste order datum"
        ];

        const separator = ";";
        const csvHeaders = displayedColumns.map((row) => row).join(separator);

        const data = resp.data.data.items;
        const csvContent =
      csvHeaders +
      "\n\n" +
      data
          .map((row) => {
              return columns
                  .map((k) => {
                      let cell = row[k] === null || row[k] === undefined ? "" : row[k];
                      cell =
                cell instanceof Date ?
                    cell.toLocaleString() :
                    cell.toString().replace(/"/g, '""');
                      if (cell.search(/("|,|\n)/g) >= 0) {
                          cell = `"${cell}"`;
                      }
                      return cell;
                  })
                  .join(separator);
          })
          .join("\n");

        downloadReport(csvContent, filename);
    });
};
