import { formatCurrency } from "./common";
import { SOURCE_CURRENCY } from "constants";
const dateFns = require("date-fns");

export const ORDER_DATE_FORMAT = (v, r, c) => {
    const originalDate = new Date(v);
    return (
        originalDate.toISOString().slice(0, 10) +
    " " +
    originalDate.toISOString().slice(11, 16)
    );
};

export const ORDER_NO_FORMAT = (v, r, c) => {
    const valueToDisplay = v || r.order_id;
    const formattedValue = valueToDisplay?.toString().padStart(6, "0");
    return `<a class="cursor-pointer underline">${formattedValue}</a>`;
};

export const ORDER_STATUS_FORMAT = (v, r, c) => `<span class=order_status>
  <span class=${v.split(" ").join("-").toLowerCase()}>${v} </span>
</span>`;

export const LINK_FORMAT = (v, r, c) =>
    `<a class="cursor-pointer underline">${v}</a>`;

export const RECIPIENT_NAME_FORMAT = (v, r, c) =>
    `<span class=${
        r.is_recipient_complaince ? "recipient-compliance" : ""
    }>${v}</span>`;

export const DES_AMOUNT_FORMAT = (v, r, c) =>
    `${v} ${r.destination_currency_code}`;

export const SOU_AMOUNT_FORMAT = (v, r, c) => `${v} ${r.source_currency_code}`;

export const NUMBER_FORMAT = (v, r, c) => v.toLocaleString();

export const CURRENCY_FORMAT = (v, r, c) =>
    v == null ?
        `0 ${SOURCE_CURRENCY.currency_code}` :
        `${formatCurrency(v.toFixed(2))} ${SOURCE_CURRENCY.currency_code}`;

export const DECIMAL_FORMAT_2 = (v, r, c) =>
    v == null ? "0.00" : v.toFixed(2);

export const DECIMAL_FORMAT_1 = (v, r, c) => (v == null ? "N/A" : v.toFixed(1));

export const BOOLEAN_FORMAT = (v, r, c) => (v == null || v <= 0 ? "No" : "Yes");

export const PERCENT_FORMAT = (v, r, c) => (v == null ? "N/A" : v + "%");

export const DATETIME_FORMAT = (v, r, c) =>
    v == null ? "N/A" : dateFns.format(new Date(v), "yyyy-MM-dd HH:mm");

export const DATE_FORMAT = (v, r, c) =>
    v == null ? "N/A" : dateFns.format(new Date(v), "yyyy-MM-dd");

export const TIME_FORMAT = (v, r, c) =>
    v == null ? "N/A" : dateFns.format(new Date(v), "HH:mm");

export const IMAGE_FORMAT = (v, r, c) =>
    v == null || v === "" ?
        "N/A" :
        `<img alt="image" title="Image" width="50" src="${v}" class="col-small-image">`;

export const PERSON_IMAGE_FORMAT = (v, r, c) =>
    v == null || v === "" ?
        '<img title="No Image" src="/assets/images/avatar.jpg" class="col-small-image">' :
        '<img alt="image" title="Click to view" src="' +
      v +
      '" class="col-small-image">';

export const CAPTIALIZE_FORMAT = (v, r, c) => {
    return v ? `${v[0].toUpperCase()}${v.slice(1).toLowerCase()}` : "";
};

export const API_STATUS_FORMAT = (v, r, c) => {
    const cl = v ? "success" : "fail";
    return `<span class="api-status"><span class="${cl}">${
        v ? "Success" : "Failure"
    }</span></span>`;
};

export const HTTP_REQUEST = (v, r, c) => {
    const { http_method, response_code, api_endpoint } = r;
    const codeCl =
    response_code >= 200 && response_code < 300 ?
        "2xx" :
        response_code >= 300 && response_code < 400 ?
            "3xx" :
            response_code >= 400 && response_code < 500 ?
                "4xx" :
                "5xx";
    return `
      <div class="http-request">
          <span class="status-${codeCl}">${response_code}</span>
          <span class="${http_method?.toLowerCase()}">${http_method}</span>
          <span >${api_endpoint}</span>
      </div>
  `;
};

export const PERFORMED_BY = (v, r, c) => {
    return r?.by_whom ?
        `<b></b>` :
        "<b>System</b>";
};

export const ONINE_STATUS_FORMAT = (v, r, c) => {
    const value = r.is_portal_login ? "Online" : "Offline";
    return `<span class="${value.toLowerCase()}">${value}</span>`;
};

export const P_ONINE_STATUS_FORMAT = (v, r, c) => {
    const value = r.is_login ? "Online" : "Offline";
    return `<span class="${value.toLowerCase()}">${value}</span>`;
};

export const NEW_DOCS_FORMAT = (v, r, c) => {
    return `<span class="docs-count">${v}</span>`;
};

export const COMPLIANCE_STATUS = (v, r, c) => {
    const status = v ? "Pending Compliance" : "Compliant";
    const cl = v ? "rejected" : "delivered";
    return `<span class='order_status'> <span class='${cl}'>${status} </span></span>`;
};

export const SENDER_NAME_FORMAT = (
    v,
    r,
    c
) => `<a class="order_no cursor-pointer underline">
  <span class=${
    r.is_sender_complaince ? "recipient-compliance" : ""
}>${v}</span>
</a>`;

export const TAAJ_STATUS_FORMAT = (v, r, c) => {
    const status = v ? "Låst" : "Aktiv";
    const cl = v ? "rejected" : "delivered";
    return `<span class='order_status'> <span class='${cl}'>${status} </span></span>`;
};

export const NETWORK_TAGS_FORMAT = (v, r, c) => {
    let tags = "";
    if (v && v?.length) {
        v.forEach(
            (tag) => (tags = `${tags} <span class="network-tags">${tag}</span>`)
        );
    }
    return tags;
};
