import MainCard from "ui-component/cards/MainCard";
import { useDispatch, useSelector } from "store";
import { useState } from "react";
import { TableData } from "components/Table/index";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { getRecipientCompliance, getUserComplianceHistory, getUserCountries, getUserDocuments, getUserOrders, getUserRecipients, getVerificationHistory } from "store/slices/customers";
import { BOOLEAN_FORMAT, DATETIME_FORMAT, DES_AMOUNT_FORMAT, LINK_FORMAT, ORDER_NO_FORMAT, ORDER_STATUS_FORMAT, SOU_AMOUNT_FORMAT } from "services/format";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { ROW_PER_PAGE } from "constants";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Grid, Tab, Tabs } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { useEffect } from "react";
import ViewOrderDetailDialog from "components/Dialog/ViewOrderDetails/index";
import UploadDocument from "components/Dialog/UploadDocument";
import ConfirmationDialog from "components/Dialog/Confirmation";
import { CONFIRMATION_TYPES } from "constants";
import { addData, deleteData, getData } from "services";
import { encrypt } from "utils/encDes";
import Form from "components/Form";
import * as yup from "yup";
import RefreshButton from "components/RefreshButton";
import AddButton from "components/AddButton";
import AddEditCustomer from "components/AddEditCustomer";
import { GENDER } from "constants";
import { getPaymentPurposeData } from "store/slices/payment-purpose";
import { getPaymentSourceData } from "store/slices/payment-source";
import Loading from "components/Loading";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

// table header options
const verificationHistoryCells = [
    {
        id: 'created_at',
        align: 'left',
        label: 'Created',
        format: (v, r, c) => DATETIME_FORMAT(v, r, c),
        sortable: true
    },
    {
        id: 'is_verification_required',
        align: 'left',
        label: 'Verification Required?',
        format: (v, r, c) => BOOLEAN_FORMAT(v, r, c),
        sortable: true
    },
    {
        id: 'is_blocked',
        align: 'left',
        label: 'Blocked?',
        format: (v, r, c) => BOOLEAN_FORMAT(v, r, c),
        sortable: true
    },
    {
        id: 'is_locked',
        align: 'left',
        label: 'Locked?',
        format: (v, r, c) => BOOLEAN_FORMAT(v, r, c),
        sortable: true
    },
    {
        id: 'reason',
        align: 'left',
        label: 'Reason'
    },
    {
        id: 'verified_on',
        align: 'left',
        label: 'Verified On',
        format: (v, r, c) => DATETIME_FORMAT(v, r, c),
        sortable: true
    },
    {
        id: 'verify_by',
        align: 'left',
        label: 'Verified By'
    },
    {
        id: 'verified_message',
        align: 'left',
        label: 'Verified Message'
    }
];

const customerCells = [
    {
        id: 'country_name',
        label: 'Country',
        align: 'left'
    },
    {
        id: 'created_at',
        label: 'Updated On',
        format: (v, r, c) => DATETIME_FORMAT(v, r, c),
        align: 'left'
    }
];

const userComplianceCells = [
    {
        id: "is_pep_history",
        label: "Is Pep",
        align: "left"
    },
    {
        id: "is_rca_history",
        label: "Is Rca",
        align: "left"
    },
    {
        id: "is_sanction_history",
        label: "Is Sanction",
        align: "left"
    },
    {
        id: "order_no",
        label: "Order No",
        align: "left",
        format: (v, r, c) => ORDER_NO_FORMAT(v, r, c)
    },
    {
        id: "order_status",
        label: "Order Status",
        align: "left",
        format: (v, r , c) => ORDER_STATUS_FORMAT(v, r, c)
    },
    {
        id: "verify_message",
        label: "Reason",
        align: "left"
    },
    {
        id: "verify_by",
        label: "Verify by",
        align: "left"
    },
    {
        id: "verified_on",
        label: "Verified On",
        align: "left",
        format: (v, r, c) => DATETIME_FORMAT(v, r, c),
        sortable: true
    }
];

const recipientComplianceCells = [
    {
        id: 'is_sanction_history',
        label: 'Is Sanction',
        align: 'left'
    },
    {
        id: 'is_pep_history',
        label: 'Is Pep',
        align: 'left'
    },
    {
        id: 'is_rca_history',
        label: 'Is Rca',
        align: 'left'
    },
    {
        id: 'order_no',
        label: 'Order No',
        align: 'left',
        format: (v, r, c) => ORDER_NO_FORMAT(v, r, c)
    },
    {
        id: 'order_status',
        label: 'Order Status',
        align: 'left',
        format: (v, r, c) => ORDER_STATUS_FORMAT(v, r, c)
    },
    {
        id: 'verify_message',
        label: 'Reason',
        align: 'left'
    },
    {
        id: 'verify_by',
        label: 'Verify by',
        align: 'left'
    },
    {
        id: 'verified_on',
        label: 'Verified On',
        align: 'left',
        format: (v, r, c) => DATETIME_FORMAT(v, r, c),
        sortable: true
    }
];

const documentsCells = [
    {
        id: 'document_type',
        align: 'left',
        label: 'Document Type',
        sortable: true
    },
    {
        id: 'document',
        label: 'Document',
        align: 'left',
        cellClickAction: (row) => window.open(`${row.document_url}`, '_blank'),
        format: (v, r, c) => LINK_FORMAT(v, r, c)
    },
    {
        id: 'description',
        align: 'left',
        label: 'Reason'
    },
    {
        id: 'created_at',
        label: 'Uploaded At',
        align: 'left',
        format: (v, r, c) => DATETIME_FORMAT(v, r, c),
        sortable: true
    }
];

const recipientsCells = [
    {
        id: "recipient_id",
        label: "Recipient ID",
        value: "",
        hidden: true
    },
    {
        id: 'first_name',
        label: 'First Name',
        align: 'left',
        sortable: true
    },
    {
        id: 'last_name',
        label: 'Last Name',
        align: 'left',
        sortable: true
    },
    {
        id: 'mobile_no',
        align: 'left',
        label: 'Mobile Number',
        sortable: true
    },
    {
        id: 'city',
        label: 'City',
        align: 'left'
    },
    {
        id: 'country',
        align: 'left',
        label: 'Country'
    },
    {
        id: 'account_no',
        label: 'Account Number',
        align: 'left'
    }
];

const orderActions = [
    {
        icon: <VisibilityOutlinedIcon color="secondary" />,
        toolTip: 'View order detail',
        action: 'OnViewOrderDetail'
    }
];

const documentActions = [
    {
        icon: <CloudUploadIcon color="info" />,
        toolTip: 'Upload Documents',
        action: 'UploadDocuments'
    },
    {
        icon: <DeleteOutlineOutlinedIcon color="info" />,
        toolTip: 'Delete',
        action: 'OnDocumentDelete'
    }
];

const recipientsActions = [
    {
        icon: <ModeEditOutlinedIcon color="info" />,
        toolTip: 'Update Recipient',
        action: 'OnRecipientEdit'
    },
    {
        icon: <NotInterestedIcon color="info" />,
        toolTip: 'Block Recipient',
        action: 'OnRecipientBlock',
        condition: (row) => !row?.is_blacklisted_recipient
    },
    {
        icon: <LockOpenOutlinedIcon color="info" />,
        toolTip: 'Unblock Recipient',
        action: 'OnRecipientUnBlock',
        condition: (row) => row?.is_blacklisted_recipient
    },
    {
        icon: <DeleteOutlineOutlinedIcon color="info" />,
        toolTip: 'Delete Recipient',
        action: 'OnRecipientDelete'
    }
];

const initialRecipientsFieldData = [
    {
        id: 'first_name',
        label: 'First Name',
        value: ""
    },
    {
        id: 'last_name',
        label: 'Last Name',
        value: ""
    },
    {
        id: 'mobile_no',
        label: 'Mobile',
        value: ""
    },
    {
        id: 'country_id',
        type: 'select',
        label: 'Country',
        value: "",
        options: [],
        column: "country_name"
    },
    {
        id: 'city',
        label: 'City',
        value: ""
    },
    {
        id: 'account_no',
        label: 'Account No',
        value: ""
    },
    {
        id: 'user_id',
        label: 'User ID',
        value: "",
        hidden: true
    }
];

const initialCustomerFieldData = [
    {
        id: 'user_id',
        label: 'UserId',
        hidden: true
    },
    {
        id: 'user_no',
        label: 'User No',
        disable: true
    },
    {
        id: 'personal_number',
        label: 'Personal No',
        disable: true
    },
    {
        id: 'email',
        type: "email",
        label: 'Email',
        disable: true
    },
    {
        id: 'first_name',
        label: 'First Name'
    },
    {
        id: 'last_name',
        label: 'Last Name'
    },
    {
        id: 'mobile_no',
        label: 'Mobile',
        prefix: '+46'
    },
    {
        id: 'gender',
        type: 'select',
        label: 'Gender',
        value: null,
        column: "gender",
        options: Object.values(GENDER).map((el) => {
            return { gender: el };
        })
    },
    {
        id: 'pob',
        label: 'Place of Birth'
    },
    {
        id: 'transcation_limit',
        label: 'Limit'
    },
    {
        id: 'risk_level',
        label: 'Risk Level',
        type: "select",
        value: null,
        column: "risk_level",
        options: [1, 2, 3, 4, 5].map((el) => {
            return { risk_level: el };
        })
    },
    {
        id: 'payment_source_id',
        label: 'Source of Income',
        type: "select",
        column: "payment_source",
        multiple: true
    },
    {
        id: 'payment_purpose_id',
        label: 'Purpose of Service Usage',
        type: "select",
        column: "payment_purpose"
    },
    {
        id: 'country_id',
        label: 'Countries to Send Money To',
        type: "select",
        column: "country_name",
        multiple: true
    },
    {
        id: 'street',
        label: 'Street'
    },
    {
        id: 'postcode',
        label: 'Postal Code'
    },
    {
        id: 'city',
        label: 'City'
    },
    {
        id: 'is_pep',
        type: 'checkbox',
        label: 'Politically Exposed Person (PEP)?',
        value: false
    }
];

const recipientValidationSchema = yup.object({
    first_name: yup.string().required("First Name is required").min(1, "Minimum Length should be 1").max(150, "Maximum Length should be 150"),
    last_name: yup.string().required("Last Name is required").min(1, "Minimum Length should be 1").max(150, "Maximum Length should be 150"),
    mobile_no: yup.string().required("Mobile No is required").min(7, "Minimum Length should be 7").max(20, "Maximum Length should be 20"),
    city: yup.string().required("City is required").min(2, "Minimum Length should be 2").max(150, "Maximum Length should be 150"),
    account_no: yup.string().required("Account No is required").min(7, "Minimum Length should be 7").max(20, "Maximum Length should be 20"),
    country_id: yup.string().required("Country is required")
});

const customerValidationSchema = yup.object({
    personal_number: yup.string().required("Personal Number is required").min(10, "Minimum Length should be 10").max(13, "Maximum Length should be 13"),
    email: yup.string().required("Email is required"),
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    mobile_no: yup.string().required("Mobile No is required").min(9, "Minimum Length should be 9").max(9, "Maximum Length should be 9"),
    payment_source_id: yup.array().required("Payment Source is required"),
    payment_purpose_id: yup.string().required("Payment Purpose is required"),
    city: yup.string().required("City is required")
});

const CustomerDetails = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { userId } = useParams();

    const ordersCells = [
        {
            id: 'created_at',
            label: 'Created date',
            format: (v, r, c) => DATETIME_FORMAT(v, r, c),
            align: 'left',
            sortable: true
        },
        {
            id: 'order_no',
            label: 'Order no.',
            align: 'left',
            sortable: true,
            cellClickAction: (data) => handleClose({ action: "OnViewOrderDetail" }, data),
            format: (v, r, c) => ORDER_NO_FORMAT(v, r, c)
        },
        {
            id: 'remittance_id',
            label: 'Remittance Id',
            align: 'left'
        },
        {
            id: 'order_status',
            label: 'Status',
            align: 'center',
            format: (v, r ,c) => ORDER_STATUS_FORMAT(v, r, c)
        },
        {
            id: 'destination_country',
            label: 'Destination',
            align: 'left'
        },
        {
            id: 'service',
            label: 'Service',
            align: 'left'
        },
        {
            id: 'recipient_name',
            label: 'Recipient Name',
            align: 'left'
        },
        {
            id: 'recipient_mobile_no',
            label: 'Recipient nr',
            align: 'left'
        },
        {
            id: 'receiving_amount',
            label: 'Receiving Amount',
            align: 'right',
            format: (v, r, c) => SOU_AMOUNT_FORMAT(v, r, c)
        },
        {
            id: 'total_amount_to_pay',
            label: 'Total Amount',
            align: 'right',
            format: (v, r, c) => DES_AMOUNT_FORMAT(v, r, c)
        }
    ];

    const [tab, setTab] = useState(0);
    const [userLoading, setUserLoading] = useState(false);
    const [customerData, setCustomerData] = useState();
    const [orderData, setOrderData] = useState();
    const [documentData, setDocumentData] = useState();
    const [countries, setCountries] = useState();
    const [recipients, setRecipients] = useState();
    const [recipientFields, setRecipientsFields] = useState(initialRecipientsFieldData);
    const [customerFields, setCustomerFields] = useState(initialCustomerFieldData);
    const [isRecipientForm, setIsRecipientForm] = useState(false);
    const [isViewOrderDetailDialog, setIsViewOrderDetailDialog] = useState(false);
    const [isUploadDocumentsDialog, setIsUploadDocumentsDialog] = useState(false);
    const [isConfirmationDialog, setIsConfirmationDialog] = useState(false);
    const [isOnRecipientDeleteDialog, setIsOnRecipientDeleteDialog] = useState(false);

    const [verificationPage, setVerificationPage] = useState(0);
    const [verificationRowsPerPage, setVerificationRowsPerPage] = useState(ROW_PER_PAGE);
    const [customerPage, setCustomerPage] = useState(0);
    const [customerRowsPerPage, setCustomerRowsPerPage] = useState(ROW_PER_PAGE);
    const [orderPage, setOrderPage] = useState(0);
    const [orderRowsPerPage, setOrderRowsPerPage] = useState(ROW_PER_PAGE);
    const [userCompliancePage, setUserCompliancePage] = useState(0);
    const [userComplianceRowsPerPage, setUserComplianceRowsPerPage] = useState(ROW_PER_PAGE);
    const [recipientCompliancePage, setRecipientCompliancePage] = useState(0);
    const [recipientComplianceRowsPerPage, setRecipientComplianceRowsPerPage] = useState(ROW_PER_PAGE);
    const [documentPage, setDocumentPage] = useState(0);
    const [documentRowsPerPage, setDocumentRowsPerPage] = useState(ROW_PER_PAGE);
    const [recipientPage, setRecipientPage] = useState(0);
    const [recipientRowsPerPage, setRecipientRowsPerPage] = useState(ROW_PER_PAGE);

    const {
        verificationHistoryData,
        userCoutryData,
        userOrderData,
        userComplianceHistoryData,
        recipientComplianceData,
        userDocumentsData,
        userRecipientsData,
        loading
    } = useSelector((state) => state.customers);

    const { paymentPurposeData } = useSelector((state) => state.paymentPurpose);
    const { paymentSourceData } = useSelector((state) => state.paymentSource);

    useEffect(() => {
        dispatch(getVerificationHistory(`?user_id=${userId}&offset=${verificationPage*verificationRowsPerPage}&limit=${verificationRowsPerPage}`));
        dispatch(getUserCountries(userId, `?offset=${customerPage*customerRowsPerPage}&limit=${customerRowsPerPage}`));
        dispatch(getUserOrders(`?user_id=${userId}&offset=${orderPage*orderRowsPerPage}&limit=${orderRowsPerPage}`));
        dispatch(getUserComplianceHistory(`?user_id=${userId}&offset=${userCompliancePage*userComplianceRowsPerPage}&limit=${userComplianceRowsPerPage}`));
        dispatch(getRecipientCompliance(`?user_id=${userId}&offset=${recipientCompliancePage*recipientComplianceRowsPerPage}&limit=${recipientComplianceRowsPerPage}`));
        dispatch(getUserDocuments(userId, `?offset=${documentPage*documentRowsPerPage}&limit=${documentRowsPerPage}`));
        dispatch(getUserRecipients(userId, `?offset=${recipientPage*recipientRowsPerPage}&limit=${recipientRowsPerPage}`));
        dispatch(getPaymentPurposeData());
        dispatch(getPaymentSourceData());
        getData("en/admin/countries").then(data => {
            setCountries(data.data.data.items || []);
        });
    }, []);

    useEffect(() => {
        const updatedFields = recipientFields.map(el => {
            if (el.id === 'user_id') { el.value = userId }
            return el;
        });
        setRecipientsFields(updatedFields);
        if (userId) {
            setUserLoading(true);
            getData("en/admin/users/" + encrypt(userId)).then(data => {
                setCustomerData(data.data.data || []);
            }).finally(() => setUserLoading(false));
        }
    }, [userId]);

    useEffect(() => {
        if (customerData) {
            const updatedFields = customerFields.map(el => {
                el.value = el.id === "country_id" ? customerData['countriesToSend'] : customerData[el.id];
                return el;
            });
            setCustomerFields(updatedFields);
        }
    }, [customerData]);

    useEffect(() => {
        const updatedFields = recipientFields.map(el => {
            if (el.id === 'country_id') { el.options = countries }
            return el;
        });
        setRecipientsFields(updatedFields);

        const customerUpdatedFields = customerFields.map(el => {
            if (el.id === 'country_id') { el.options = countries }
            return el;
        });
        setCustomerFields(customerUpdatedFields);
    }, [countries]);

    useEffect(() => {
        const updatedFields = customerFields.map(el => {
            if (el.id === 'payment_purpose_id') { el.options = paymentPurposeData?.items || [] }
            return el;
        });
        setCustomerFields(updatedFields);
    }, [paymentPurposeData]);

    useEffect(() => {
        const updatedFields = customerFields.map(el => {
            if (el.id === 'payment_source_id') { el.options = paymentSourceData?.items || [] }
            return el;
        });
        setCustomerFields(updatedFields);
    }, [paymentSourceData]);

    const refreshData = () => {
        dispatch(getUserDocuments(`?user_id=${userId}&offset=${orderPage*orderRowsPerPage}&limit=${orderRowsPerPage}`));
        dispatch(getUserRecipients(`?user_id=${userId}&offset=${orderPage*orderRowsPerPage}&limit=${orderRowsPerPage}`));
    };

    const handleClose = (action = null, data = null) => {
        if (action) {
            if (action.action === "OnViewOrderDetail") {
                setOrderData(data);
                setIsViewOrderDetailDialog(true);
            } else if (action.action === "UploadDocuments") {
                setIsUploadDocumentsDialog(true);
            } else if (action.action === "OnDocumentDelete") {
                setDocumentData(data);
                setIsConfirmationDialog(true);
            } else if (action.action === "OnRecipientBlock" || action.action === "OnRecipientUnBlock") {
                const method = data.blacklist_id ? 'delete' : 'post';
                const endPoint = data.blacklist_id ? `en/admin/blacklist-recipient/${encrypt(data.blacklist_id)}` : `en/admin/blacklist-recipient`;

                if (method === 'post') {
                    addData(endPoint, { mobile_no: data.mobile_no }).finally(data => dispatch(getUserRecipients(userId)));
                } else {
                    deleteData(endPoint, { mobile_no: data.mobile_no }).finally(data => dispatch(getUserRecipients(userId)));
                }
            } else if (action.action === "OnRecipientDelete") {
                setRecipients(data);
                setIsOnRecipientDeleteDialog(true);
            } else if (action.action === "OnRecipientEdit") {
                setRecipients(data);
                setRecipientsFields(
                    [...recipientFields, {
                        id: 'recipient_id',
                        label: 'Recipient ID',
                        hidden: true,
                        value: ""
                    }].map((el) => {
                        return { ...el, value: data[el.id] };
                    })
                );
                setIsRecipientForm(true);
            }
        }
    };

    const onSort = (key, order) => {
        if (tab === 1) {
            const query = `?user_id=${userId}&offset=${verificationPage*verificationRowsPerPage}&limit=${verificationRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getVerificationHistory(query));
        } else if (tab === 2) {
            const query = `?offset=${customerPage*customerRowsPerPage}&limit=${customerRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getUserCountries(userId, query));
        } else if (tab === 3) {
            const query = `?user_id=${userId}&offset=${orderPage*orderRowsPerPage}&limit=${orderRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getUserOrders(query));
        } else if (tab === 4) {
            const query = `?user_id=${userId}&offset=${userCompliancePage*userComplianceRowsPerPage}&limit=${userComplianceRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getUserComplianceHistory(query));
        } else if (tab === 5) {
            const query = `?user_id=${userId}&offset=${recipientCompliancePage*recipientComplianceRowsPerPage}&limit=${recipientComplianceRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getRecipientCompliance(query));
        } else if (tab === 6) {
            const query = `?offset=${documentPage*documentRowsPerPage}&limit=${documentRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getUserDocuments(userId, query));
        } else if (tab === 7) {
            const query = `?offset=${recipientPage*recipientRowsPerPage}&limit=${recipientRowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`;
            dispatch(getUserRecipients(userId, query));
        }
    };

    const onPageChange = (page=0, limit=ROW_PER_PAGE) => {
        if (tab === 1) {
            setVerificationPage(page);
            setVerificationRowsPerPage(limit);
            dispatch(getVerificationHistory(`?user_id=${userId}&offset=${page*limit}&limit=${limit}`));
        } else if (tab === 2) {
            setCustomerPage(page);
            setCustomerRowsPerPage(limit);
            dispatch(getUserCountries(userId, `?offset=${page*limit}&limit=${limit}`));
        } else if (tab === 3) {
            setOrderPage(page);
            setOrderRowsPerPage(limit);
            dispatch(getUserOrders(`?user_id=${userId}&offset=${page*limit}&limit=${limit}`));
        } else if (tab === 4) {
            setUserCompliancePage(page);
            setUserComplianceRowsPerPage(limit);
            dispatch(getUserComplianceHistory(`?user_id=${userId}&offset=${page*limit}&limit=${limit}`));
        } else if (tab === 5) {
            setRecipientCompliancePage(page);
            setRecipientComplianceRowsPerPage(limit);
            dispatch(getRecipientCompliance(`?user_id=${userId}&offset=${page*limit}&limit=${limit}`));
        } else if (tab === 6) {
            setDocumentPage(page);
            setDocumentRowsPerPage(limit);
            dispatch(getUserDocuments(userId, `?offset=${page*limit}&limit=${limit}`));
        } else if (tab === 7) {
            setRecipientPage(page);
            setRecipientRowsPerPage(limit);
            dispatch(getUserRecipients(userId, `?offset=${page*limit}&limit=${limit}`));
        }
    };

    const handleChange = (event, newValue) => setTab(newValue);

    const callback = () => {
        refreshData();
        if (isUploadDocumentsDialog === true) {
            setIsUploadDocumentsDialog(false);
            dispatch(getUserDocuments(userId, `?offset=${orderPage*orderRowsPerPage}&limit=${orderRowsPerPage}`));
        } else if (isConfirmationDialog === true) {
            setDocumentData();
            setIsConfirmationDialog(false);
            dispatch(getUserDocuments(userId, `?offset=${orderPage*orderRowsPerPage}&limit=${orderRowsPerPage}`));
        } else if (isOnRecipientDeleteDialog === true) {
            setRecipients();
            setIsOnRecipientDeleteDialog(false);
        } else if (isRecipientForm === true) {
            setRecipients();
            setRecipientsFields(initialRecipientsFieldData);
            setIsRecipientForm(false);
        }
    };

    return (
        <MainCard content={false}>
            <Tabs
                value={tab}
                variant="scrollable"
                onChange={handleChange}
                sx={{
                    mb: 3,
                    mx: 3,
                    "& a": {
                        minHeight: "auto",
                        minWidth: 80,
                        py: 2,
                        px: 1,
                        mr: 4,
                        color: theme.palette.grey[600],
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "600"
                    },
                    "& a.Mui-selected": {
                        color: theme.palette.primary.main
                    },
                    "& a > svg": {
                        mb: "0px !important",
                        mr: 1.1
                    }
                }}
            >
                <Tab
                    component={Link}
                    to="#"
                    label={`CUSTOMER PERSONAL INFO`}
                    {...a11yProps(0)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`VERIFICATION HISTORY`}
                    {...a11yProps(1)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`CUSTOMER'S LIST`}
                    {...a11yProps(2)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`ORDERS`}
                    {...a11yProps(3)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`USER COMPLIANS`}
                    {...a11yProps(4)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`RECIPIENT COMPLIANCE`}
                    {...a11yProps(5)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`DOCUMENTS`}
                    {...a11yProps(6)}
                />
                <Tab
                    component={Link}
                    to="#"
                    label={`RECIPIENTS`}
                    {...a11yProps(7)}
                />
            </Tabs>

            {tab === 0 && (
                <>
                    {userLoading && (
                        <Loading />
                    )}
                    <AddEditCustomer
                        fields={customerFields}
                        validationSchema={customerValidationSchema}
                        type={CONFIRMATION_TYPES.UPDATE}
                        url={`en/admin/users/${encrypt(userId)}`}
                    />
                </>
            )}

            {tab === 1 && (
                <TableData
                    rows={verificationHistoryData}
                    headCells={verificationHistoryCells}
                    onSort={onSort}
                    loading={loading}
                    page={verificationPage}
                    rowsPerPage={verificationRowsPerPage}
                    onPageChange={onPageChange}
                />
            )}

            {tab === 2 && (
                <TableData
                    rows={userCoutryData}
                    headCells={customerCells}
                    onSort={onSort}
                    loading={loading}
                    page={customerPage}
                    rowsPerPage={customerRowsPerPage}
                    onPageChange={onPageChange}
                />
            )}

            {tab === 3 && (
                <TableData
                    rows={userOrderData}
                    headCells={ordersCells}
                    actions={orderActions}
                    handleClose={handleClose}
                    onSort={onSort}
                    loading={loading}
                    page={orderPage}
                    rowsPerPage={orderRowsPerPage}
                    onPageChange={onPageChange}
                />
            )}

            {tab === 4 && (
                <TableData
                    rows={userComplianceHistoryData}
                    headCells={userComplianceCells}
                    onSort={onSort}
                    loading={loading}
                    page={userCompliancePage}
                    rowsPerPage={userComplianceRowsPerPage}
                    onPageChange={onPageChange}
                />
            )}

            {tab === 5 && (
                <TableData
                    rows={recipientComplianceData}
                    headCells={recipientComplianceCells}
                    onSort={onSort}
                    loading={loading}
                    page={recipientCompliancePage}
                    rowsPerPage={recipientComplianceRowsPerPage}
                    onPageChange={onPageChange}
                />
            )}

            {tab === 6 && (
                <>
                    <Grid sx={{ margin: '0 15px 20px 0', justifyContent: "end", display: "flex" }}>
                        <AddButton addData={() => setIsUploadDocumentsDialog(true)} />
                        <RefreshButton refreshData={refreshData} />
                    </Grid>
                    <TableData
                        rows={userDocumentsData}
                        headCells={documentsCells}
                        actions={documentActions}
                        handleClose={handleClose}
                        onSort={onSort}
                        loading={loading}
                        page={documentPage}
                        rowsPerPage={documentRowsPerPage}
                        onPageChange={onPageChange}
                    />
                </>
            )}

            {tab === 7 && (
                <>
                    <Grid sx={{ margin: '0 15px 20px 0', justifyContent: "end", display: "flex" }}>
                        <AddButton addData={() => setIsRecipientForm(true)} />
                        <RefreshButton refreshData={refreshData} />
                    </Grid>
                    <TableData
                        rows={userRecipientsData}
                        headCells={recipientsCells}
                        actions={recipientsActions}
                        handleClose={handleClose}
                        onSort={onSort}
                        loading={loading}
                        page={recipientPage}
                        rowsPerPage={recipientRowsPerPage}
                        onPageChange={onPageChange}
                    />
                </>
            )}

            {isViewOrderDetailDialog && (
                <ViewOrderDetailDialog
                    open={isViewOrderDetailDialog}
                    setOpen={setIsViewOrderDetailDialog}
                    order={orderData}
                />
            )}

            {isUploadDocumentsDialog && (
                <UploadDocument
                    open={isUploadDocumentsDialog}
                    setOpen={callback}
                    userId={userId}
                />
            )}

            {isConfirmationDialog && (
                <ConfirmationDialog
                    open={isConfirmationDialog}
                    setOpen={callback}
                    title="Delete Record"
                    message="Are you sure you want to Delete this Record?"
                    url={`en/admin/users/:user_id/documents/${documentData.document_id}`}
                    type={CONFIRMATION_TYPES.DELETE}
                />
            )}

            {isOnRecipientDeleteDialog && (
                <ConfirmationDialog
                    open={isOnRecipientDeleteDialog}
                    setOpen={callback}
                    title="Delete Record"
                    message="Are you sure you want to Delete this Record?"
                    url={`en/admin/user/${encrypt(userId)}/recipients/${encrypt(recipients.recipient_id)}`}
                    type={CONFIRMATION_TYPES.DELETE}
                />
            )}

            {isRecipientForm && (
                <Form
                    open={isRecipientForm}
                    callback={callback}
                    title={recipients ? "Edit Record" : "Add Record"}
                    fields={recipientFields}
                    validationSchema={recipientValidationSchema}
                    url={recipients ? `en/admin/user/${encrypt(userId)}/recipients/${encrypt(recipients.recipient_id)}` : `en/admin/user/${encrypt(userId)}/recipients`}
                    type={recipients ? CONFIRMATION_TYPES.UPDATE : CONFIRMATION_TYPES.INSERT}
                />
            )}
        </MainCard>
    );
};

export default CustomerDetails;
