import { Outlet } from "react-router-dom";
import { lazy } from "react";
import GuestGuard from "utils/route-guard/GuestGuard";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
const AuthLogin = Loadable(
    lazy(() => import("views/authentication/Login"))
);

const LoginRoutes = {
    path: "/",
    element: (
        <NavMotion>
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            index: true,
            element: <AuthLogin />
        },
        {
            path: "/login",
            element: <AuthLogin />
        }
    ]
};

export default LoginRoutes;
