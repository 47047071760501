import { Grid, Stack, Typography, useMediaQuery, useTheme, Box } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import Chip from "ui-component/extended/Chip";
import { ORDER_STATUS_COLOR, GRID_SPACING} from "constants";

const Details = ({ data }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
                <SubCard>
                    {data.map((el, index) => (
                        <Stack
                            key={index}
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={isSmallScreen ? 0.5 : 1}
                            alignItems={isSmallScreen ? "flex-start" : "center"}
                            sx={{ mb: 2 }} // Added margin-bottom for spacing between rows
                        >
                            <Typography variant="h5" sx={{ mb: isSmallScreen ? 0.5 : 2, whiteSpace: 'nowrap' }}>
                                {el.key} :
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? 'flex-start' : 'flex-end', width: '100%' }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textAlign: isSmallScreen ? 'left' : 'right',
                                        ml: isSmallScreen ? 0 : 'auto'
                                    }}
                                >
                                    {el.key === "Status" ? (
                                        <Chip
                                            label={el.value}
                                            variant="filled"
                                            size="small"
                                            chipcolor={ORDER_STATUS_COLOR[el.value]}
                                        />
                                    ) : (
                                        el.value
                                    )}
                                </Typography>
                            </Box>
                        </Stack>
                    ))}
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default Details;
