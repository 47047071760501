import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import axios from 'utils/axios';
import { useEffect, useState } from 'react';

export default function ThemeRoutes() {
  const [routes, setRoutes] = useState([LoginRoutes]);

  useEffect(() => {
    const fetchFilteredRoutes = async () => {
      axios.get('/en/admin/me').then(data => {
        const role = data.data.data.role
        const filteredRoutes = MainRoutes.children.filter((route) => route.role.includes(role))
        setRoutes([LoginRoutes, { ...MainRoutes, children: filteredRoutes }]);
      })
    };

    if(routes.length === 1) {
      fetchFilteredRoutes();
    }
  }, [window.location.pathname]);

  return useRoutes(routes);
}
