import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";

const initialState = {
    error: null,
    complianceData: [],
    loading: false
};

const slice = createSlice({
    name: "compliance",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getSuccess(state, action) {
            state.complianceData = action.payload;
        },

        hasLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export default slice.reducer;

export function getComplianceData(query="") {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await axios.request("en/admin/user-compliance" + query);
            dispatch(slice.actions.getSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}
