import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import snackbarReducer from "./slices/snackbar";
import menuReducer from "./slices/menu";
import ordersReducer from "./slices/order";
import relationWithRecipientReducer from "./slices/relation-with-recipient";
import paymentPurposeReducer from "./slices/payment-purpose";
import paymentSourceReducer from "./slices/payment-source";
import serviceTypeReducer from "./slices/service-type";
import serviceNetworkReducer from "./slices/service-network";
import deliveryServicesReducer from "./slices/delivery-services";
import exchangeRateReducer from "./slices/exchange-rate";
import countriesReducer from "./slices/countries";
import partnersLogsReducer from "./slices/partners-logs";
import auditLogsReducer from "./slices/audit-logs";
import partnersReducer from "./slices/partners";
import onlineUsersReducer from "./slices/online-users";
import complianceReducer from "./slices/compliance";
import adminReducer from "./slices/admin";
import customersReducer from "./slices/customers";
import recipientsReducer from "./slices/recipients";

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    order: persistReducer(
        {
            key: "admin",
            storage,
            keyPrefix: "malsom"
        },
        ordersReducer
    ),
    relationWithRecipient: relationWithRecipientReducer,
    paymentPurpose: paymentPurposeReducer,
    paymentSource: paymentSourceReducer,
    serviceType: serviceTypeReducer,
    serviceNetwork: serviceNetworkReducer,
    deliveryService: deliveryServicesReducer,
    exchangeRate: exchangeRateReducer,
    countries: countriesReducer,
    partnersLogs: partnersLogsReducer,
    auditLogs: auditLogsReducer,
    partners: partnersReducer,
    onlineUsers: onlineUsersReducer,
    compliance: complianceReducer,
    admin: adminReducer,
    customers: customersReducer,
    recipients: recipientsReducer
});

export default reducer;
