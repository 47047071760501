import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";
import { ROLES } from "constants";

const initialState = {
    error: null,
    adminData: [],
    loading: false
};

const slice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getSuccess(state, action) {
            state.adminData = action.payload;
        },

        hasLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export default slice.reducer;

export function getAdminData(query="") {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await axios.request(`en/admin/users?role=${ROLES.ADMIN}` + query);
            dispatch(slice.actions.getSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}
