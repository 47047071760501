import {
    IconShoppingCart,
    IconUser,
    IconEye,
    IconWifi,
    IconFriends,
    IconFileText,
    IconFileRss,
    IconFilePlus,
    IconFolder
} from "@tabler/icons";

import {
    VerifiedUserOutlined,
    HourglassTopOutlined
} from "@mui/icons-material";
import { ROLES } from "constants";
import { IconLayoutDashboardFilled } from "@tabler/icons-react";

const dashboard = {
    id: "dashboard",
    icon: IconLayoutDashboardFilled,
    type: "group",
    children: [
        {
            id: "dashbaord",
            title: "Dashbaord",
            type: "item",
            url: "/dashboard",
            icon: IconLayoutDashboardFilled,
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            id: "analytics",
            title: "Analytics",
            type: "item",
            url: "/dashboard/analytics",
            icon: IconLayoutDashboardFilled,
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            id: "orders",
            title: "Orders",
            type: "item",
            url: "/orders",
            icon: IconShoppingCart,
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            id: "admins",
            title: "Admins",
            type: "item",
            url: "/users",
            icon: IconUser,
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            id: "compliance-group",
            title: "Compliance",
            type: "collapse",
            icon: IconFileText,
            children: [
                {
                    id: "active",
                    title: "Active",
                    type: "item",
                    icon: HourglassTopOutlined,
                    url: "/compliance",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                },
                {
                    id: "user-compliance-history",
                    title: "User Compliance History",
                    type: "item",
                    icon: VerifiedUserOutlined,
                    url: "/compliance/user-compliance-history",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                },
                {
                    id: "recipient-compliance-history",
                    title: "Recipient Compliance History",
                    type: "item",
                    icon: VerifiedUserOutlined,
                    url: "/compliance/recipient-compliance-history",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                }
            ]
        },

        {
            id: "online-users",
            title: "Online Users",
            type: "item",
            url: "/online-users",
            icon: IconWifi,
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            id: "partners",
            title: "Partners",
            type: "item",
            url: "/partners",
            icon: IconFriends,
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            id: "logs",
            title: "Logs",
            type: "collapse",
            icon: IconFileText,
            children: [
                {
                    id: "audit-logs",
                    title: "Audit Logs",
                    type: "item",
                    icon: IconFilePlus,
                    url: "/logs/audit-logs",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                },
                {
                    id: "partner-logs",
                    title: "Partner logs",
                    type: "item",
                    icon: IconFileRss,
                    url: "/logs/partner-logs",
                    breadcrumbs: false,
                    role: [ROLES.SUPER_ADMIN]
                }
            ]
        },
        {
            id: "documentation",
            title: "Documentation",
            type: "item",
            url: "/documentation",
            icon: IconFolder,
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        }
    ]
};

export default dashboard;
