import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { jwtDecode } from "jwt-decode";
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";
import Loader from "ui-component/Loader";
import axios from "utils/axios";

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem("serviceToken", serviceToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem("serviceToken");
    // delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem("serviceToken");
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get("/en/admin/me", {
                        headers: {
                            Authorization: "Bearer " + serviceToken,
                            "x-api": process.env.REACT_APP_PLATFORM,
                            "web-api-key": process.env.REACT_APP_GET_API_KEY
                        }
                    });

                    const { data } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: data
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        try {
            const response = await axios.post(
                "en/auth/login",
                {
                    email,
                    password
                },
                {
                    headers: {
                        "x-api": process.env.REACT_APP_PLATFORM,
                        "web-api-key": process.env.REACT_APP_POST_API_KEY
                    }
                }
            );

            const { token } = response.data;

            // Set token and update state
            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: response.data.user
                }
            });
        } catch (error) {
            const errorMessage = error.response?.data?.err?.message || error?.data?.err?.message || error?.err?.message || 'Login failed';
            // Throw the error message to be handled in AuthLogin
            throw new Error(errorMessage);
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
