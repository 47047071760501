import { toast } from 'react-toastify';
import * as CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_IV);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY);

const isEncEnabled = process.env.REACT_APP_ENC_DEC_ENABLED?.toLowerCase() || "0";

// Helper function to convert Base64 to URL-safe Base64
const base64ToUrlSafe = (base64) => {
    return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

// Helper function to convert URL-safe Base64 to Base64
const urlSafeToBase64 = (urlSafe) => {
    let base64 = urlSafe.replace(/-/g, "+").replace(/_/g, "/");
    while (base64.length % 4) {
        base64 += "=";
    }
    return base64;
};

export const encrypt = (data) => {
    try {
        if (isEncEnabled !== "true" && isEncEnabled !== "1") {
            return data;
        }

        const encrypted = CryptoJS.AES.encrypt(data.toString(), key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        const base64Encrypted = encrypted.toString();
        return base64ToUrlSafe(base64Encrypted);
    } catch (err) {
        console.log("Getting error while encryption: ", err);
        return null; // Return null or handle error accordingly
    }
};

export const decrypt = (encryptedData) => {
    try {
        // If encryption is not enabled, return the data as is
        if (isEncEnabled !== "true" && isEncEnabled !== "1") {
            return encryptedData;
        }

        // Check if encryptedData is valid before trying to decrypt
        if (typeof encryptedData !== 'string' || encryptedData.trim() === '') {
            throw new TypeError("Invalid encrypted data provided for decryption.");
        }

        const base64Encrypted = urlSafeToBase64(encryptedData);
        const decryptedBytes = CryptoJS.AES.decrypt(base64Encrypted, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedData) {
            throw new Error("Decryption failed, resulting data is empty.");
        }

        return decryptedData;
    } catch (err) {
        toast.error(`'Getting error while decryption': ${err}`); // Show toast error message
        console.log("Getting error while decryption: ", err);
        return null; // Return null or handle error accordingly
    }
};
