import { GRID_SPACING, CONFIRMATION_TYPES } from "constants";
import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { addData, getData, updateData } from "services";
import { useFormik } from "formik";

const AddEditCustomer = ({ fields = [], validationSchema, type = "", url, onClose=() =>{} }) => {
    const theme = useTheme();

    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if (fields.length > 0) {
            let obj = {};
            fields.forEach((el) => (obj[el.id] = el.prefix ? el.value?.replace(el.prefix, "") : el.value));
            setInitialValues(obj);
        } else {
            setInitialValues({});
        }
    }, [fields]);

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (values.mobile_no) { values.mobile_no = "+46" + values.mobile_no }
            values.countriesToSend = values.country_id;
            delete values.country_id;
            delete values.user_no;

            if (type === CONFIRMATION_TYPES.INSERT) {
                await addData(url, values);
            } else if (type === CONFIRMATION_TYPES.UPDATE) {
                await updateData(url, values);
            }
            values.mobile_no = values.mobile_no.replace("+46", "");
            onClose();
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid sx={{ padding: "20px 35px" }} container spacing={GRID_SPACING}>
                {fields.map((el, index) => {
                    switch (el.type) {
                    case "select":
                        return (
                            <Grid
                                display={el?.hidden && "none"}
                                sx={{ margin: "5px 0" }}
                                item
                                key={index}
                                xs={12}
                                md={4}
                            >
                                <FormControl fullWidth>
                                    <InputLabel id={el.id}>{el.label}</InputLabel>
                                    {el.multiple ? (
                                        <Select
                                            id={el.id}
                                            name={el.id}
                                            label={el.label}
                                            defaultValue={formik.values[el.id]}
                                            value={formik.values[el.id] || []}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched[el.id] && Boolean(formik.errors[el.id])
                                            }
                                            helpertext={formik.touched[el.id] && formik.errors[el.id]}
                                            disabled={el.disable}
                                            multiple={el.multiple}
                                        >
                                            {el.options?.map((option, index) => (
                                                <MenuItem key={index} value={option[el.id]}>
                                                    {option[el.column]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Select
                                            id={el.id}
                                            name={el.id}
                                            label={el.label}
                                            defaultValue={formik.values[el.id]}
                                            value={formik.values[el.id] || ""}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched[el.id] && Boolean(formik.errors[el.id])
                                            }
                                            helpertext={formik.touched[el.id] && formik.errors[el.id]}
                                            disabled={el.disable}
                                        >
                                            {el.options?.map((option, index) => (
                                                <MenuItem key={index} value={option[el.id]}>
                                                    {option[el.column]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                    {formik.errors[el.id] && formik.touched[el.id] ? (
                                        <div className="error">{formik.errors[el.id]}*</div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                        );
                    case "file":
                        return (
                            <Grid
                                display={el?.hidden && "none"}
                                sx={{ margin: "5px 0" }}
                                item
                                key={index}
                                xs={12}
                                md={4}
                            >
                                <TextField
                                    fullWidth
                                    type={el.type}
                                    id={el.id}
                                    name={el.id}
                                    multiple={el.multiple}
                                    value={formik.values[el.id] || ""}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched[el.id] && Boolean(formik.errors[el.id])
                                    }
                                    helpertext={formik.touched[el.id] && formik.errors[el.id]}
                                    disabled={el.disable}
                                />
                                {formik.errors[el.id] && formik.touched[el.id] ? (
                                    <div className="error">{formik.errors[el.id]}*</div>
                                ) : null}
                            </Grid>
                        );

                    case "checkbox":
                        return (
                            <Grid
                                display={el?.hidden && "none"}
                                item
                                key={index}
                                xs={12}
                                sx={{ display: "flex", margin: "5px 0" }}
                                md={4}
                            >
                                <Checkbox
                                    value={formik.values[el.id] || false}
                                    id={el.id}
                                    name={el.id}
                                    label={el.label}
                                    color="primary"
                                    sx={{ color: theme.palette.primary.main }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched[el.id] && Boolean(formik.errors[el.id])
                                    }
                                    helpertext={formik.touched[el.id] && formik.errors[el.id]}
                                    disabled={el.disable}
                                />
                                <Typography sx={{ paddingTop: 1.3 }}>{el.label}</Typography>
                                {formik.errors[el.id] && formik.touched[el.id] ? (
                                    <div className="error">{formik.errors[el.id]}*</div>
                                ) : null}
                            </Grid>
                        );

                    default:
                        return (
                            <Grid
                                display={el?.hidden && "none"}
                                sx={{ margin: "5px 0" }}
                                item
                                key={index}
                                xs={12}
                                md={4}
                            >
                                <TextField
                                    fullWidth
                                    type={el.type || "text"}
                                    id={el.id}
                                    name={el.id}
                                    label={el.label}
                                    value={formik.values[el.id] || ""}
                                    onChange={async (event) => {
                                        formik.setFieldValue(el.id, event.target.value);
                                        if (el.id === "personal_number") {
                                            const res = await getData(`validate-personnummer?personnummer=${event.target.value}`);
                                            if (res && res.valid === false) {
                                                formik.setFieldError(el.id, res.message);
                                                // formik.validateField(el.id)
                                            }
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched[el.id] && Boolean(formik.errors[el.id])
                                    }
                                    helpertext={formik.touched[el.id] && formik.errors[el.id]}
                                    disabled={el.disable}
                                    InputProps={{
                                        startAdornment: el.prefix && (<InputAdornment position='start'>{el.prefix}</InputAdornment>)
                                        // endAdornment: el.id === "personal_number" && (
                                        //   <InputAdornment position="end">
                                        //       <VisibilityOff />
                                        //   </InputAdornment>
                                        // )
                                    }}
                                />
                                {formik.errors[el.id] && formik.touched[el.id] ? (
                                    <div className="error">{formik.errors[el.id]}*</div>
                                ) : null}
                            </Grid>
                        );
                    }
                })}
            </Grid>
            <Grid sx={{ margin: 2, display: "flex", justifyContent: "end" }}>
                <Button
                    sx={{
                        color: theme.palette.error.dark,
                        borderColor: theme.palette.error.dark
                    }}
                    onClick={onClose}
                    color="secondary"
                    type="reset"
                >
          Cancel
                </Button>
                <Button variant="contained" type="submit" autoFocus>
                    {type === CONFIRMATION_TYPES.INSERT ? "Add" : "Update"}
                </Button>
            </Grid>
        </form>
    );
};

export default AddEditCustomer;
