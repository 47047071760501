import Routes from "routes";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import ThemeCustomization from "themes";
import "./App.css";
import { JWTProvider as AuthProvider } from "contexts/JWTContext";

const App = () => {
    return (
        <ThemeCustomization>
            <RTLLayout>
                <NavigationScroll>
                    <AuthProvider>
                        <Routes />
                        <Snackbar />
                        <ToastContainer />
                    </AuthProvider>
                </NavigationScroll>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
