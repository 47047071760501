import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import { addData, deleteData, updateData, updatePatchData } from "services";
import { CONFIRMATION_TYPES } from "constants";

export default function ConfirmationDialog({
    open,
    setOpen,
    title,
    payload={},
    message,
    url,
    type,
    formKey,
    required,
    onSuccess=() => {}
}) {
    const theme = useTheme();
    const [text, setText] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const submit = async () => {
        setTimeout(() => {
          handleClose()
        }, 1000)
        try {
            if (type === CONFIRMATION_TYPES.INSERT) {
                await addData(url, payload);
            } else if (type === CONFIRMATION_TYPES.DELETE) {
                await deleteData(url);
            } else if (type === CONFIRMATION_TYPES.PATCH) {
                if (required && formKey) {
                    payload[formKey] = text;
                }
                await updatePatchData(url, payload);
            } else if (type === CONFIRMATION_TYPES.UPDATE) {
                if (required && formKey) {
                    payload[formKey] = text;
                }
                await updateData(url, payload);
            }
            onSuccess()
        } catch (error) {
            // Handle error here if needed
            console.error("An error occurred during submission", error);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <div style={{ padding: "0 30px" }}>
                        <DialogTitle
                            id="alert-dialog-title"
                            style={{ textAlign: "center" }}
                        >
                            {title}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="body2" component="span">
                                    {message}
                                </Typography>
                            </DialogContentText>
                            {formKey && (
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="text"
                                    name="text"
                                    label={formKey}
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    required={required}
                                    sx={{ marginTop: 2 }}
                                />
                            )}

                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{
                                    color: theme.palette.error.dark,
                                    borderColor: theme.palette.error.dark
                                }}
                                onClick={handleClose}
                                color="secondary"
                            >
                No
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={submit}
                                autoFocus
                            >
                Yes
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </Dialog>
        </div>
    );
}
